import { useColorModeValue } from "@chakra-ui/react";

const Status = ({ styles }) => (
  <iframe
    src={`https://retro.instatus.com/embed-status/${useColorModeValue(
      "light",
      "dark"
    )}-sm`}
    title="Retro Service Status"
    width="230"
    height="61"
    frameBorder="0"
    scrolling="no"
    style={{ border: "none", ...styles }}
  />
);

export default Status;
