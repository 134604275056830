import {
  Box,
  Stack,
  StackDivider,
  Button,
  ButtonGroup,
  IconButton,
  Text,
  SimpleGrid,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { LinkedinLogo, TwitterLogo } from "@phosphor-icons/react";
import Logo from "../Logo";
import Link from "next/link";
import ThemeToggler from "../ThemeToggler";
import Status from "./Status";

export const Footer = () => (
  <Box
    as="footer"
    role="contentinfo"
    mx="auto"
    maxW="7xl"
    py="12"
    px={{
      base: "4",
      md: "8",
    }}
  >
    <Stack spacing="10" divider={<StackDivider />}>
      <Stack
        direction={{
          base: "column",
          lg: "row",
        }}
        spacing={{
          base: "10",
          lg: "28",
        }}
      >
        <Box flex="1">
          <Logo
            color={useColorModeValue("gray.800", "whiteAlpha.700")}
            h={10}
          />

          <Box mt={4}>
            <Text fontSize="sm" mb={4}>
              Made with ❤️ in the UK
            </Text>
            <Status />
          </Box>
        </Box>
        <Stack
          direction={{
            base: "column",
            md: "row",
          }}
          spacing={{
            base: "10",
            md: "20",
          }}
        >
          <LinkGrid
            spacing={{
              base: "10",
              md: "20",
              lg: "28",
            }}
            flex="1"
          />
          <SubscribeForm
            width={{
              base: "full",
              md: "sm",
            }}
          />
        </Stack>
      </Stack>
      <Stack
        direction={{
          base: "column-reverse",
          md: "row",
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Copyright />
        <SocialMediaLinks />
      </Stack>
    </Stack>
  </Box>
);

export const Copyright = () => {
  const color = useColorModeValue("gray.700", "whiteAlpha.600");

  return (
    <Text
      fontSize="sm"
      textAlign={{ base: "center", md: "left" }}
      color={color}
    >
      &copy; {new Date().getFullYear()} RetroPlan all rights reserved.
    </Text>
  );
};

const FooterHeading = (props) => (
  <Heading
    as="h2"
    color={useColorModeValue("gray.600", "whiteAlpha.600")}
    fontSize="sm"
    fontWeight="semibold"
    textTransform="uppercase"
    letterSpacing="wider"
    {...props}
  />
);

const LinkGrid = (props) => (
  <SimpleGrid columns={2} {...props}>
    <Box minW="130px">
      <FooterHeading mb="4">Product</FooterHeading>
      <Stack>
        <Link href="/pricing">Pricing</Link>
        <Link href="/product/planning-poker">Planning Poker</Link>
        <Link href="/product/retrospective">Retrospective</Link>
      </Stack>
    </Box>
    <Box minW="130px">
      <FooterHeading mb="4">Legal</FooterHeading>
      <Stack>
        <Link href="/privacy">Privacy</Link>
        <Link href="/terms">Terms</Link>
        <Link href="/about">About us</Link>
        <Link href="/contact">Contact us</Link>
      </Stack>
    </Box>
  </SimpleGrid>
);

const SocialMediaLinks = (props) => (
  <ButtonGroup variant="ghost" colorScheme="gray" {...props}>
    <IconButton
      as="a"
      target="_blank"
      rel="noopener"
      href="https://twitter.com/retroplanio"
      aria-label="Twitter"
      icon={<TwitterLogo fontSize="20px" weight="bold" />}
    />
    <ThemeToggler />
  </ButtonGroup>
);

const SubscribeForm = (props) => {
  return (
    <Stack spacing="4">
      <FooterHeading>Follow us on Social</FooterHeading>

      <Text>Follow us on social media to get the latest updates and news.</Text>
      <Stack spacing={4} direction="row">
        <Button
          as="a"
          target="_blank"
          rel="noopener"
          href="https://twitter.com/retroplanio"
          flexShrink={0}
          colorScheme="twitter"
          variant="link"
          leftIcon={<TwitterLogo weight="fill" />}
        >
          Twitter
        </Button>

        <Button
          as="a"
          target="_blank"
          rel="noopener"
          href="https://www.linkedin.com/company/retroplanio/"
          flexShrink={0}
          colorScheme="linkedin"
          variant="link"
          leftIcon={<LinkedinLogo weight="fill" />}
        >
          LinkedIn
        </Button>
      </Stack>
    </Stack>
  );
};

export default Footer;
