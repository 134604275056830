import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Avatar,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Link,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { auth } from "lib/firebase";
import {
  Cards,
  CaretDown,
  CaretRight,
  Kanban,
  List,
  X,
} from "@phosphor-icons/react";
import { useAuthState } from "react-firebase-hooks/auth";
import { default as NextLink } from "next/link";
import ThemeToggler from "@components/ThemeToggler";
import Logo from "@components/Logo";
import { useRouter } from "next/router";
import { useEffect, useState, useRef } from "react";

const NAV_ITEMS = [
  {
    label: "Pricing",
    href: "/pricing",
  },
  {
    label: "Product",
    children: [
      {
        label: "Planning Poker",
        icon: Cards,
        subLabel:
          "Plan difficulty points for your tasks & reach an overall consensus",
        href: "/product/planning-poker",
      },
      {
        label: "Retrospective",
        icon: Kanban,
        subLabel: "Create a retrospective to reflect on your team's progress",
        href: "/product/retrospective",
      },
    ],
  },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Contact",
    href: "/contact",
  },
];

export default function WithSubnavigation() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const btnRef = useRef();
  const [user, loading] = useAuthState(auth);
  const router = useRouter();

  return (
    <Flex
      width="100%"
      maxW="5xl"
      mx={{ base: 0, lg: "auto" }}
      left="50%"
      transform="translateX(-50%)"
      position="fixed"
      top={{
        base: 0,
        lg: 4,
      }}
      zIndex={4}
      rounded={{
        base: 0,
        lg: "md",
      }}
      bg={useColorModeValue("whiteAlpha.600", "whiteAlpha.100")}
      _hover={{
        bg: useColorModeValue("whiteAlpha.900", "whiteAlpha.200"),
      }}
      borderWidth={{ base: 0, md: 1 }}
      borderBottomWidth={1}
      backdropFilter="saturate(140%) blur(25px)"
      color={useColorModeValue("gray.600", "white")}
      transition="all .3s cubic-bezier(0.25,0.46,0.45,0.94);"
      px={{ base: 4, xl: 2.5 }}
      minH="60px"
      alignItems="center"
    >
      {/* Mobile navigation button */}
      <Box mr={2} display={{ base: "flex", md: "none" }}>
        <IconButton
          onClick={onToggle}
          icon={isOpen ? <X w={3} h={3} /> : <List w={5} h={5} />}
          variant="outline"
          colorScheme="gray"
          aria-label="Toggle Navigation"
        />
      </Box>

      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <NextLink href="/">
            <Logo
              cursor="pointer"
              _hover={{ opacity: 0.8 }}
              h={8}
              color={useColorModeValue("gray.800", "white")}
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
            />
          </NextLink>

          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          alignItems="center"
          justify="flex-end"
          direction="row"
          spacing={2}
        >
          {!loading &&
            (user ? (
              <NextLink href="/dashboard">
                <Flex alignItems="center" cursor="pointer" gap={2}>
                  <Avatar
                    display="inline-flex"
                    size="sm"
                    name={user.displayName}
                    src={user.photoURL}
                  />

                  <Text mr={2} css={{ textWrap: "nowrap" }}>
                    {user.displayName}
                  </Text>
                </Flex>
              </NextLink>
            ) : (
              <Flex alignItems="center" gap={4}>
                <ThemeToggler />
                <NextLink href="/login">
                  <Button display={{ base: "none", md: "inline-flex" }}>
                    Sign In
                  </Button>
                </NextLink>
              </Flex>
            ))}
        </Stack>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody>
            <MobileNav />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction="row" spacing={2}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Text cursor="pointer" as="span">
                <NextLink href={navItem.href || "#"}>
                  <Box
                    py={2}
                    px={3}
                    fontSize="sm"
                    fontWeight={500}
                    color={linkColor}
                    rounded="base"
                    _hover={{
                      textDecoration: "none",
                      color: linkHoverColor,
                      bg: useColorModeValue("gray.50", "gray.700"),
                    }}
                  >
                    {navItem.label}
                  </Box>
                </NextLink>
              </Text>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                bg={popoverContentBgColor}
                p={4}
                rounded="xl"
                minW="sm"
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel, icon }) => {
  const subLinkColor = useColorModeValue("brand.700", "brand.300");

  return (
    <NextLink href={href}>
      <Link
        role={"group"}
        display={"block"}
        p={2}
        rounded={"md"}
        _hover={{ bg: useColorModeValue("brand.50", "gray.700") }}
      >
        <Stack direction={"row"} align={"center"}>
          <Box>
            <Flex mb={2} alignItems="center">
              <Icon
                width="21px"
                height="21px"
                as={icon}
                transition={"all .1s ease"}
                _groupHover={{ color: subLinkColor }}
                mr={2}
                aria-label={`${label} icon`}
              />
              <Text
                transition={"all .1s ease"}
                _groupHover={{ color: subLinkColor }}
                fontWeight={500}
              >
                {label}
              </Text>
            </Flex>

            <Text
              color={useColorModeValue("gray.400", "gray.300")}
              fontSize="sm"
            >
              {subLabel}
            </Text>
          </Box>
          <Flex
            transition="all .1s ease"
            transform="translateX(-10px)"
            opacity={0}
            _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
            justify="flex-end"
            align="center"
            flex={1}
          >
            <Icon color={subLinkColor} w={5} h={5} as={CaretRight} />
          </Flex>
        </Stack>
      </Link>
    </NextLink>
  );
};

const MobileNav = () => (
  <Stack p={4} display={{ md: "none" }}>
    {NAV_ITEMS.map((navItem) => (
      <MobileNavItem key={navItem.label} {...navItem} />
    ))}
  </Stack>
);

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  const color = useColorModeValue("gray.600", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontWeight={600} color={color}>
          {label}
        </Text>
        {children && (
          <Icon
            as={CaretDown}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor={borderColor}
          align="start"
        >
          {children &&
            children.map((child) => (
              <Box key={child.label} py={2}>
                <NextLink href={child.href}>{child.label}</NextLink>
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
