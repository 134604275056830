import {
  IconButton,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { Moon, Sun } from "@phosphor-icons/react";

const ThemeToggler = (props) => {
  const { toggleColorMode } = useColorMode();

  return (
    <Tooltip label={`Switch to ${useColorModeValue("Dark", "Light")}`}>
      <IconButton
        datatestid="switchTheme"
        colorScheme="gray"
        variant="ghost"
        aria-label={`Switch to ${useColorModeValue("Dark", "Light")} mode`}
        icon={useColorModeValue(
          <Moon weight="bold" fontSize="20px" />,
          <Sun weight="bold" fontSize="20px" />
        )}
        onClick={toggleColorMode}
        {...props}
      />
    </Tooltip>
  );
};

export default ThemeToggler;
