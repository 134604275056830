import { chakra, useColorModeValue } from "@chakra-ui/react";

const Logo = (props) => {
  const color = useColorModeValue("brand.900", "brand.300");

  return (
    <chakra.svg
      color={color}
      aria-hidden
      viewBox="0 0 163 49"
      fill="none"
      h="6"
      flexShrink={0}
      {...props}
    >
      <path
        d="M57.844 31.012C61.068 34.236 59.664 41.36 60.392 45H68.66C67.568 40.736 69.128 31.428 64.76 28.048C67.152 26.592 67.932 23.472 67.932 19.208C67.932 11.408 64.552 7.872 54.828 7.872H46.56V45H54.828V31.012H57.844ZM54.828 14.58C58.832 14.58 59.664 16.14 59.664 20.3C59.664 24.46 58.832 25.5 54.828 25.5V14.58ZM78.7663 15.1H87.7623V7.872H70.4983V45H87.7623V37.772H78.7663V29.66H85.1623V22.432H78.7663V15.1ZM105.266 15.1H112.702V7.872H89.5616V15.1H96.9976V45H105.266V15.1ZM125.799 31.012C129.023 34.236 127.619 41.36 128.347 45H136.615C135.523 40.736 137.083 31.428 132.715 28.048C135.107 26.592 135.887 23.472 135.887 19.208C135.887 11.408 132.507 7.872 122.783 7.872H114.515V45H122.783V31.012H125.799ZM122.783 14.58C126.787 14.58 127.619 16.14 127.619 20.3C127.619 24.46 126.787 25.5 122.783 25.5V14.58ZM148.638 14.476C151.238 14.476 151.55 16.4 151.55 26.488C151.55 36.784 151.238 38.396 148.638 38.396C146.038 38.396 145.726 36.784 145.726 26.488C145.726 16.4 146.038 14.476 148.638 14.476ZM148.638 7.248C137.458 7.248 136.626 12.968 136.626 26.488C136.626 40.476 137.458 45.624 148.638 45.624C159.818 45.624 160.65 40.476 160.65 26.488C160.65 12.968 159.818 7.248 148.638 7.248Z"
        fill="currentColor"
      />
      <path
        d="M24.4986 35.2188L36.75 42.875V9.1875H12.25V42.875L24.4986 35.2188Z"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.75 29.6562L24.4987 22L12.25 29.6562"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </chakra.svg>
  );
};

export default Logo;
